import {
	mobileCode,
	registerConfig,
	wechatMobileCode
} from "@/api/auth/login"
import {
	adList,
	captcha
} from "@/api/website"

export default {
	data: () => {
		var isMobile = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("หมายเลขโทรศัพท์ต้องไม่ว่างเปล่า"))
			} else {
				const reg = /((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4}))/

				if (reg.test(value)) {
					callback()
				} else {
					callback(new Error("โปรดป้อนหมายเลขโทรศัพท์มือถือที่ถูกต้อง"))
				}
			}
		}

		return {
			qrcodeData: {
				time: 0,
				timer: 0,
			},
			wx_key: '',
			expire_time: '',
			ischecked: false,
			ischecked1: false,
			loginMode: "account",
			activeName: "first", // tabแฮนด์ออฟ
			formData: {
				account: "",
				password: "",
				vercode: "",
				mobile: "",
				dynacode: "",
				key: "",
				checked: false,
				autoLoginRange: 7
			}, // ข้อมูลฟอร์ม
			captcha: {
				id: "",
				img: ""
			}, // แคปต์ชา
			dynacodeData: {
				seconds: 120,
				timer: null,
				codeText: "รับโค้ดแบบไดนามิก",
				isSend: false
			}, // รหัสแบบไดนามิก
			isSub: false, // การส่งการป้องกันการทําซ้ํา
			registerConfig: {
				is_enable: 1
			},
			accountRules: {
				account: [{
					required: true,
					message: "กรุณากรอกรหัสล็อกอินของคุณ",
					trigger: "blur"
				}],
				password: [{
					required: true,
					message: "กรุณาใส่รหัสผ่านเข้าสู่ระบบของคุณ",
					trigger: "blur"
				}],
				vercode: [{
					required: true,
					message: "กรุณากรอกรหัสยืนยัน",
					trigger: "blur"
				}]
			},
			mobileRules: {
				mobile: [{
					required: true,
					validator: isMobile,
					trigger: "blur"
				}],
				vercode: [{
					required: true,
					message: "กรุณากรอกรหัสยืนยัน",
					trigger: "blur"
				}],
				dynacode: [{
					required: true,
					message: "กรุณากรอกรหัสไดนามิกของ SMS",
					trigger: "blur"
				}]
			},
			wechatRules: {
				mobile: [{
					required: true,
					validator: isMobile,
					trigger: "blur"
				}],
				vercode: [{
					required: true,
					message: "กรุณากรอกรหัสยืนยัน",
					trigger: "blur"
				}],
				dynacode: [{
					required: true,
					message: "กรุณากรอกรหัสไดนามิกของ SMS",
					trigger: "blur"
				}]
			},
			codeRules: {
				mobile: [{
					required: true,
					validator: isMobile,
					trigger: "blur"
				}],
				vercode: [{
					required: true,
					message: "กรุณากรอกรหัสยืนยัน",
					trigger: "blur"
				}]
			},
			loadingAd: true,
			adList: [],
			backgroundColor: '',
			img: '',
			third_party: 0
		}
	},
	created() {
		this.ischecked = this.$route.params.third_party;
		if(this.ischecked){
			this.weixinLogin()
		}
		this.getAdList()
		this.getCaptcha()
		this.getRegisterConfig()

		let that = this;
		document.onkeypress = function(e) {
			var keycode = document.all ? event.keyCode : e.which;
			if (keycode == 13) {
				if (that.activeName == "first") {
					that.accountLogin('ruleForm'); // ชื่อวิธีการล็อกอิน
				} else {
					that.mobileLogin('mobileRuleForm'); // ชื่อวิธีการล็อกอิน
				}

				return false;
			}
		};
	},
	watch: {
		"dynacodeData.seconds": {
			handler(newValue, oldValue) {
				if (newValue == 0) {
					clearInterval(this.dynacodeData.timer)
					this.dynacodeData = {
						seconds: 120,
						timer: null,
						codeText: "รับโค้ดแบบไดนามิก",
						isSend: false
					}
				}
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		getAdList() {
			adList({
					keyword: "NS_PC_LOGIN"
				})
				.then(res => {
					if (res.code == 0 && res.data.adv_list) {
						this.adList = res.data.adv_list
						for (let i = 0; i < this.adList.length; i++) {
							if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
						}
						this.backgroundColor = this.adList[0].background
					}

					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		handleClick(tab, event) {
			if (this.activeName == "first") {
				this.loginMode = "account"
			} else {
				this.loginMode = "mobile"
			}
		},
		handleChange(curr, pre) {
			this.backgroundColor = this.adList[curr].background
		},
		/**
		 * เข้าสู่ระบบบัญชี
		 */
		accountLogin(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var data = {
						username: this.formData.account,
						password: this.formData.password
					}

					if (this.captcha.id != "") {
						data.captcha_id = this.captcha.id
						data.captcha_code = this.formData.vercode
					}

					if (this.formData.checked) {
						data.autoLoginRange = this.formData.autoLoginRange
					}

					if (this.isSub) return
					this.isSub = true

					this.$store
						.dispatch("member/login", data)
						.then(res => {
							if (res.code >= 0) {
								this.$notify({
									message: "เข้าสู่ระบบสําเร็จ！",
									title: 'เรียบร้อยแล้ว',type: "success"
								})
								if (this.$route.query.redirect) {
									const a = this.$route.query.redirect
									const b = this.$route.query
									this.$router.push(this.$route.query.redirect)

								} else {
									this.$router.push({
										name: "member"
									})
								}
							} else {
								this.isSub = false
								this.getCaptcha()
								this.$notify({
									message: res.message,
									title: "คำเตือน",type: "warning"
								})
							}
						})
						.catch(err => {
							this.isSub = false
							this.$notify.error({title: 'Error',message: err.message});
							this.getCaptcha()
						})
				} else {
					return false
				}
			})
		},

		/**
		 * เข้าสู่ระบบด้วยหมายเลขโทรศัพท์ของคุณ
		 */
		mobileLogin(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var data = {
						mobile: this.formData.mobile,
						key: this.formData.key,
						code: this.formData.dynacode
					}

					if (this.captcha.id != "") {
						data.captcha_id = this.captcha.id
						data.captcha_code = this.formData.vercode
					}

					if (this.isSub) return
					this.isSub = true

					this.$store
						.dispatch("member/mobile_login", data)
						.then(res => {
							if (res.code >= 0) {
								this.$notify({
									message: "เข้าสู่ระบบสําเร็จ！",
									title: 'เรียบร้อยแล้ว',type: "success"
								})
								if (this.$route.query.redirect) {
									this.$router.push(this.$route.query.redirect)
								} else {
									this.$router.push({
										name: "member"
									})
								}
							} else {
								this.isSub = false
								this.getCaptcha()
								this.$notify({
									message: res.message,
									title: "คำเตือน",type: "warning"
								})
							}
						})
						.catch(err => {
							this.isSub = false
							this.$notify.error({title: 'Error',message: err.message});
							this.getCaptcha()
						})
				} else {
					return false
				}
			})
		},
		
		/**
		 * เข้าสู่ระบบ WeChat
		 */
		wechatLogin(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var data = {
						mobile: this.formData.mobile,
						key: this.formData.key,
						code: this.formData.dynacode
					}
		
					if (this.captcha.id != "") {
						data.captcha_id = this.captcha.id
						data.captcha_code = this.formData.vercode
					}
		
					if (this.isSub) return
					this.isSub = true
		
					
					this.$store
						.dispatch("wechat/wechatLogin", data)
						.then(res => {

							if (res.code >= 0) {
								this.$notify({
									message: "เข้าสู่ระบบสําเร็จ！",
									title: 'เรียบร้อยแล้ว',type: "success"
								})
								if (this.$route.query.redirect) {
									this.$router.push(this.$route.query.redirect)
								} else {
									this.$router.push({
										name: "member"
									})
								}
							} else {
								this.isSub = false
								this.getCaptcha()
								this.$notify({
									message: res.message,
									title: "คำเตือน",type: "warning"
								})
							}
						})
						.catch(err => {
							this.isSub = false
							this.$notify.error({title: 'Error',message: err.message});
							this.getCaptcha()
						})
				} else {
					return false
				}
			})
		},
		weixinLogin() {
			this.ischecked = true;
			this.$store
				.dispatch("wechat/logincode")
				.then(res => {
					if (res.code >= 0) {
						this.img = res.data.qrcode;
						this.wx_key = res.data.key;
						this.expire_time = res.data.expire_time;
						this.qrcodeData.timer = setInterval(() => {this.checkLogin()}, 2000);
					}
				})
		},

		// ตรวจหาว่ามีการสแกนรหัสหรือไม่
		checkLogin() {
			this.qrcodeData.time += 2;
			if (this.qrcodeData.time > this.expire_time) {
				clearInterval(this.qrcodeData.timer);
				return;
			};
			var data = {
				key: this.wx_key
			};
			this.$store
				.dispatch("wechat/checklogin", data)
				.then(res => {
					console.log(res)
					if (res.code >= 0) {
						if(res.data.token != undefined){
							this.$notify({
								message: "เข้าสู่ระบบสําเร็จ！",
								title: 'เรียบร้อยแล้ว',type: "success"
							})
							if (this.$route.query.redirect) {
								this.$router.push(this.$route.query.redirect)
							} else {
								this.$router.push({
									name: "member"
								})
							}
						}else{
							this.ischecked1 = true;
						}
						
						clearInterval(this.qrcodeData.timer);
					}
				})
				.catch(err => {
					console.log(err.message)  
				})
		},

		closeWx() {
			this.ischecked = false;
		},
		closeWx1() {
			this.ischecked = false;
			this.ischecked1 = false;
		},

		/**
		 * รับการกําหนดค่าการลงทะเบียน
		 */
		getRegisterConfig() {
			registerConfig()
				.then(res => {
					if (res.code >= 0) {
						this.registerConfig = res.data.value
						if (this.registerConfig.register.indexOf('username') != -1) {
							this.loginMode = 'account';
						} else {
							this.loginMode = 'mobile';
						}
					}
				})
				.catch(err => {
					console.log(err.message)
				})
		},
		/**
		 * รับรหัสยืนยัน
		 */
		getCaptcha() {
			captcha({
					captcha_id: this.captcha.id
				})
				.then(res => {
					if (res.code >= 0) {
						this.captcha.id = res.data.id
						this.captcha.img = res.data.img
						this.captcha.img = this.captcha.img.replace(/\r\n/g, "")
					}
				})
				.catch(err => {
					this.$notify.error({title: 'Error',message: err.message});
				})
		},
		/**
		 * ส่งรหัสไดนามิกของโทรศัพท์มือถือ
		 */
		sendMobileCode(formName) {
			if (this.dynacodeData.seconds != 120) return
			this.$refs[formName].clearValidate("dynacode")

			this.$refs[formName].validateField("mobile", valid => {
				if (valid) {
					return false
				}
			})
			this.$refs[formName].validateField("vercode", valid => {
				if (!valid) {
					mobileCode({
							mobile: this.formData.mobile,
							captcha_id: this.captcha.id,
							captcha_code: this.formData.vercode
						})
						.then(res => {
							if (res.code >= 0) {
								this.formData.key = res.data.key
								if (this.dynacodeData.seconds == 120 && this.dynacodeData.timer ==
									null) {
									this.dynacodeData.timer = setInterval(() => {
										this.dynacodeData.seconds--
										this.dynacodeData.codeText = this.dynacodeData.seconds +
											"sสามารถซื้อซ้ําได้"
									}, 1000)
								}
							}
						})
						.catch(err => {
							this.$notify.error({title: 'Error',message: err.message});
						})
				} else {
					return false
				}
			})
		},
		/**
		 * ส่ง WeChat เพื่อผูกรหัสแบบไดนามิกของโทรศัพท์มือถือ
		 */
		sendWechatMobileCode(formName) {
			if (this.dynacodeData.seconds != 120) return
			this.$refs[formName].clearValidate("dynacode")
		
			this.$refs[formName].validateField("mobile", valid => {
				if (valid) {
					return false
				}
			})
			this.$refs[formName].validateField("vercode", valid => {
				if (!valid) {
					wechatMobileCode({
							mobile: this.formData.mobile,
							captcha_id: this.captcha.id,
							captcha_code: this.formData.vercode
						})
						.then(res => {
							if (res.code >= 0) {
								console.log(res.data.code)
								this.formData.key = res.data.key
								if (this.dynacodeData.seconds == 120 && this.dynacodeData.timer ==
									null) {
									this.dynacodeData.timer = setInterval(() => {
										this.dynacodeData.seconds--
										this.dynacodeData.codeText = this.dynacodeData.seconds +
											"sสามารถซื้อซ้ําได้"
									}, 1000)
								}
							}
						})
						.catch(err => {
							this.$notify.error({title: 'Error',message: err.message});
						})
				} else {
					return false
				}
			})
		},
	}
}
