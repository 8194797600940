<template>
	<div class="ns-login-wrap" :style="{background: backgroundColor}" v-loading="loadingAd" style=" height: 551px;">
		<div class="el-row-wrap el-row-wrap-login">
			<el-row>
				<el-col :span="13">
					<el-carousel height="550px" class="ns-login-bg" @change="handleChange" v-if="adList.length">
						<el-carousel-item v-for="item in adList" :key="item.adv_id">
							<el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
						</el-carousel-item>
					</el-carousel>
				</el-col>
				<el-col :span="11" class="ns-login-form" style="    float: right;
    box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px;
    margin-right: 70px;margin-top: 48px;
    border-radius: 24px;
    background: #ffffff; ">
					<div class="grid-content bg-purple">
						<el-tabs v-model="activeName"  @tab-click="handleClick">
							<el-tab-pane label="รหัสผ่าน" name="first" style="font-family: 'sukhumvittadmai2';
    padding-left: 0;
    font-size: 17px;">
								<el-form v-if="activeName == 'first'" :model="formData" :rules="accountRules" ref="ruleForm">
									<el-form-item prop="account">
										<el-input v-model="formData.account" placeholder="กรุณากรอกหมายเลขบัญชี">
											<template slot="prepend">
												<i class="iconfont icon-zhanghao"></i>
											</template>
										</el-input>
									</el-form-item>
									<el-form-item prop="password">
										<el-input type="password" v-model="formData.password" autocomplete="off" placeholder="กรุณาใส่รหัสผ่านเข้าสู่ระบบของคุณ">
											<template slot="prepend">
												<i class="iconfont icon-mima"></i>
											</template>
										</el-input>
									</el-form-item>
									<el-form-item prop="vercode">
										<el-input v-model="formData.vercode" autocomplete="off" placeholder="กรุณากรอกรหัสยืนยัน" maxlength="4">
											<template slot="prepend">
												<i class="iconfont icon-yanzhengma"></i>
											</template>
											<template slot="append">
												<img :src="captcha.img" mode class="captcha" @click="getCaptcha" />
											</template>
										</el-input>
									</el-form-item>
									<el-form-item >
										<el-row>
											<el-col :span="12"><el-checkbox v-model="formData.checked">จำชื่อฉันไว้</el-checkbox></el-col>
											<el-col :span="12" class="ns-forget-pass"><router-link to="/find_pass" class>ลืมรหัสผ่าน</router-link></el-col>
										</el-row>
									</el-form-item>
									<el-form-item style="margin-bottom: 18px;"><el-button type="primary" @click="accountLogin('ruleForm')" style=" font-family: 'sukhumvittadmai2';">เข้าสู่ระบบ</el-button></el-form-item>

									<el-form-item style="    margin-bottom: -2px;"> 
										<el-row>
											<el-col :span="24">
												<div class="bg-purple-light" style="padding: 0 0 10px 0; border-bottom: 1px solid #ebebeb; font-family: 'sukhumvittadmai'; border-bottom: 1px solid rgb(235 235 235 / 0%);">ไม่มีบัญชี？<router-link to="/register"><p style="color: #fd274a; font-family: 'sukhumvittadmai2'; margin-top: -4px; ">ลงทะเบียนเลย</p></router-link><!-- <i class="iconfont icon-arrow-right"></i> --></div>
											</el-col>
										</el-row>
										<el-col :span="18" style="display: none;">
											<div style="margin-left: 80px; margin-top: 20px; position: relative;" class="go-wx-login iconfont icon-weixin-copy" @click="weixinLogin()">
												<p style="font-size: 14px; text-indent: 10px; position: absolute;top: 1px;">เข้าสู่ระบบด้วย Facebook</p>
											</div>
										</el-col>
									</el-form-item>
								</el-form>
							</el-tab-pane>

							<el-tab-pane label="เบอร์มือถือ" name="second" v-if="registerConfig.register.indexOf('mobile') != -1" style="font-family: 'sukhumvittadmai2';
    padding-left: 0;
    font-size: 17px;" >
								<el-form
									v-if="activeName == 'second'"
									:model="formData"
									:rules="mobileRules"
									ref="mobileRuleForm"
									class="ns-login-mobile"
								>
									<el-form-item prop="mobile">
										<el-input v-model="formData.mobile" placeholder="กรุณากรอกหมายเลขโทรศัพท์ของคุณ">
											<template slot="prepend">
												<i class="iconfont icon-shouji-copy"></i>
											</template>
										</el-input>
									</el-form-item>

									<el-form-item prop="vercode">
										<el-input v-model="formData.vercode" autocomplete="off" placeholder="กรุณากรอกรหัสยืนยัน" maxlength="4">
											<template slot="prepend">
												<i class="iconfont icon-yanzhengma"></i>
											</template>
											<template slot="append">
												<img :src="captcha.img" mode class="captcha" @click="getCaptcha" />
											</template>
										</el-input>
									</el-form-item>

									<el-form-item prop="dynacode">
										<el-input v-model="formData.dynacode" maxlength="4" placeholder="กรุณาใส่รหัส SMS แบบไดนามิก">
											<template slot="prepend">
												<i class="iconfont icon-dongtaima"></i>
											</template>
											<template slot="append">
												<div
													class="dynacode"
													:class="dynacodeData.seconds == 120 ? 'ns-text-color' : 'ns-text-color-gray'"
													@click="sendMobileCode('mobileRuleForm')"
												>
													{{ dynacodeData.codeText }}
												</div>
											</template>
										</el-input>
									</el-form-item>

									<el-form-item><el-button type="primary" @click="mobileLogin('mobileRuleForm')" style=" font-family: 'sukhumvittadmai2';">เข้าสู่ระบบ</el-button></el-form-item>

									<el-form-item>
										<el-row>
											<el-col :span="24">
												<div class="bg-purple-light"><router-link to="/register">ลงทะเบียนตอนนี้</router-link><i class="iconfont icon-arrow-right"></i></div>
											</el-col>
										</el-row>
									</el-form-item>
								</el-form>
							</el-tab-pane>
							
						</el-tabs>
					</div>
				</el-col>
				<div class="wx-login" :class="ischecked == true ? 'wx-login-display' : ''">
					<p class="wx-login-title">WeChat สแกนการเข้าสู่ระบบรหัส</p>
					<div class="qrcode">
						<img :src="img">
					</div>
					<div style="display: flex;">
						<p class="iconfont icon-arrowLeft" @click="closeWx()"></p>
						<p class="wx-login-footer" @click="closeWx()">เข้าสู่ระบบด้วยรหัสผ่านบัญชีของคุณ</p>
					</div>
				</div>
				
				<div class="wx-login1" :class="ischecked1 == true ? 'wx-login-display1' : ''">
					<p class="wx-login-title1">สแกนโค้ดสําเร็จ</p> 
					

				<el-col :span="11" class="ns-login-form ns-login-form2">
					<div class="grid-content bg-purple">
								<el-form :model="formData" :rules="wechatRules" ref="wechatRuleForm">
									<el-form-item prop="mobile">
										<el-input v-model="formData.mobile" placeholder="กรุณากรอกหมายเลขโทรศัพท์ของคุณ">
											<template slot="prepend">
												<i class="iconfont icon-shouji-copy"></i>
											</template>
										</el-input>
									</el-form-item>
									
									<el-form-item prop="vercode">
										<el-input v-model="formData.vercode" autocomplete="off" placeholder="กรุณากรอกรหัสยืนยัน" maxlength="4">
											<template slot="prepend">
												<i class="iconfont icon-yanzhengma"></i>
											</template>
											<template slot="append">
												<img :src="captcha.img" mode class="captcha" @click="getCaptcha" />
											</template>
										</el-input>
									</el-form-item> 
									
									<el-form-item prop="dynacode">
										<el-input v-model="formData.dynacode" maxlength="4" placeholder="กรุณาใส่รหัส SMS แบบไดนามิก">
											<template slot="prepend">
												<i class="iconfont icon-dongtaima"></i>
											</template>
											<template slot="append">
												<div
													class="dynacode"
													:class="dynacodeData.seconds == 120 ? 'ns-text-color' : 'ns-text-color-gray'"
													@click="sendWechatMobileCode('wechatRuleForm')"
												>
													{{ dynacodeData.codeText }}
												</div>
											</template>
										</el-input>
									</el-form-item>
									
									<el-form-item><el-button type="primary" @click="wechatLogin('wechatRuleForm')">ยืนยัน</el-button></el-form-item>
						
									<el-form-item>
										<el-row>
											<el-col :span="12">
												<div class="go-wx-login iconfont" @click="closeWx1()">
													<p>ลงชื่อเข้าใช้โดยใช้วิธีอื่น</p>
												</div>
											</el-col>
											<el-col :span="12">
												<div class="bg-purple-light"><router-link to="/register">ลงทะเบียนตอนนี้</router-link><i class="iconfont icon-arrow-right"></i></div>
											</el-col>
										</el-row>
									</el-form-item>
								</el-form>
					</div>
					</el-col>
						
					 
					
				</div>
				
			</el-row>
		</div>
	</div>
</template>

<script>
import login from './login';
export default {
	name: 'login',
	mixins: [login]
};
</script>
<style lang="scss" scoped>
	
	.icon-arrowLeft {
		line-height: 55px;
		font-size: 30px;
		margin-right: 10px;
		margin-left: 130px;
	}
.ns-login-wrap {
	width: 100%;
	height: 500px;
	min-width: $width;
	.el-row-wrap-login {
		width: 1200px;
		margin: 0 auto;
		.ns-login-bg {
			margin-top: 40px;
		}


		.ns-login-form {
			width: 400px;
			margin-left: 50px;
			background: #ffffff;
			margin-top: 25px;

			.el-form {
				.captcha {
					vertical-align: top;
					max-width: inherit;
					max-height: 38px;
					line-height: 38px;
					cursor: pointer;
				}

				.dynacode {
					cursor: pointer;
				}

				[class*=' el-icon-'],
				[class^='el-icon-'] {
					font-size: 16px;
				}
			}
			.grid-content {
				padding: 10px 20px;
			}

			.el-form-item__error {
				padding-left: 50px;
			}

			button {
				width: 100%;
			}

			.ns-forget-pass {
				text-align: right;
			}
			
			i {
				font-size: 18px;
			}
			
			.bg-purple-light {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				
				i {
					width: 16px;
					height: 16px;
					line-height: 16px;
					text-align: center;
					border-radius: 50%;
					background-color: $base-color;
					color: #FFFFFF;
					font-size: 12px;
					margin-left: 8px;
				}
			}
		}
		
		.ns-login-form2{
			margin-left: 9px;
		}
	}
}

.icon-weixin-copy {
	color: #09bb07;
	font-size: 26px; 
	
	p {
		font-size: 16px;
		color: #000;
		display: inline-block;
		padding-right: 15px;
	}
}

.go-wx-login {
	cursor: pointer;
}

.wx-login-display {
	display: block !important;
}

.wx-login {
	width: 410px;
	height: 460px;
	background: #fff;
	position: absolute;
	top: 22px;
	right: 90px;
	z-index: 10; 
	display: none;
	
	.wx-login-title {
		text-align: center;
		margin-top: 30px;
		font-weight: 600;
		font-size: 16px;
		color: #fc183e;
	}
	img {
		width: 200px; 
		margin: 45px 105px;
	}
	.wx-login-footer {
		margin-top: 15px;
		text-align: center;
		cursor: pointer;
	}
}

.wx-login-display1 {
	display: block !important;
}

.wx-login1 {
	width: 410px;
	height: 460px;
	background: #fff;
	position: absolute;
	top: 22px;
	right: 90px;
	z-index: 11; 
	display: none;
	
	.wx-login-title1 {
		text-align: center;
		margin-top: 30px;
		font-weight: 600;
	}
	.wx-login-footer1 {
		margin-top: 15px;
		text-align: center;
		cursor: pointer;
	}
}
</style>

<style lang="scss">
.ns-login-form {
	.el-form-item__error {
		/* ข้อความแสดงข้อผิดพลาด */
		padding-left: 57px;
	}

	.el-tabs__active-bar,
	.el-tabs__nav-wrap::after {
		/* กําจัดtabติดป้ายชื่อเส้นแนวนอนด้านล่าง */
		height: 0;
	}

	/* ลงทะเบียนตอนนี้ */
	.el-form-item__content {
		line-height: 20px;
	}
}.ns-login-wrap {
    width: 100%;
    height: 551px;
    min-width: 1210px;
}.ns-login-wrap .el-row-wrap-login .ns-login-bg {
    margin-top: 70px;
}.ns-login-wrap .el-row-wrap-login .ns-login-form{
    width: 400px;
    margin-left: 50px;
    background: #ffffff;
    margin-top: 53px;
}.ns-login-form .el-tabs__active-bar, .ns-login-form .el-tabs__nav-wrap::after {
    height: 1.5px;
}.el-col-18 {
    width: 100%;
}.el-input-group__append {
    width: 115px;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}.ns-login-wrap .el-row-wrap-login .ns-login-bg {
    margin-top: 68px;
}.el-tabs--top .el-tabs__item.is-top:nth-child(2), .el-tabs--top .el-tabs__item.is-bottom:nth-child(2), .el-tabs--bottom .el-tabs__item.is-top:nth-child(2), .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2) {
  font-family: 'sukhumvittadmai2';
  padding-left: 0;
   font-size: 14px;
}.el-tabs--top .el-tabs__item.is-top:last-child, .el-tabs--top .el-tabs__item.is-bottom:last-child, .el-tabs--bottom .el-tabs__item.is-top:last-child, .el-tabs--bottom .el-tabs__item.is-bottom:last-child {
    font-family: 'sukhumvittadmai2';
    padding-left: 0;
    font-size: 14px;
}.el-tabs__item.is-active {
    font-size: 16px !important;
}.ns-login-wrap .el-row-wrap-login .ns-login-bg {
    margin-top: 0px !important;
}
</style>
